import { Box, Flex, Text, Icon, Divider } from "@chakra-ui/react";
import { PhoneIcon, EnvelopeIcon, MapPinIcon } from "@heroicons/react/24/solid";

function Footer() {
  return (
    <Flex
      direction="column"
      w="100%"
      mt="80"
      bg="#131313"
      color="#8f9a8e"
      pt="10"
      pb="10"
      pl="4"
      pr="4"
      alignItems="center"
      justifyContent="center"
      fontSize={{ base: "12px", md: "lg" }}
    >
      <Text>Al-Bilad Catalyst Co. Ltd. All Rights Reserved © 2023</Text>
    </Flex>
  );
}

export default Footer;
