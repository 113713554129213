import { Box, Center, Flex, Heading, Link } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";

function Header() {
  const [headerBackground, setHeaderBackground] = useState("transparent");

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 100;

      if (scrollPosition > threshold) {
        setHeaderBackground("black");
      } else {
        setHeaderBackground("transparent");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Flex
      pt="2"
      pb="2"
      position="fixed"
      zIndex="99"
      width={{ base: "100vw" }}
      fontSize={{ base: "16px", md: "16px" }}
      // mx="auto"
      pl={{ md: "8" }}
      alignItems="flex-start"
      height="auto"
      justifyContent="left"
      bg={headerBackground}
    >
      <ScrollLink to="about" smooth={true} duration={600} offset={-35}>
        <Link
          mr="3"
          color="#00acf0"
          borderBottom="2px solid"
          borderColor="#00acf0"
          width="40%"
          p="2"
          rounded="sm"
          _hover={{ bg: "black", color: "white", borderBottom: "none" }}
        >
          About Us
        </Link>
      </ScrollLink>
      <ScrollLink to="services" smooth={true} duration={700} offset={0}>
        <Link
          mr="3"
          color="#00acf0"
          borderBottom="2px solid"
          borderColor="#00acf0"
          width="40%"
          p="2"
          rounded="sm"
          _hover={{ bg: "black", color: "white", borderBottom: "none" }}
        >
          Services
        </Link>
      </ScrollLink>
      <ScrollLink to="stats" smooth={true} duration={700} offset={-60}>
        <Link
          mr="3"
          color="#00acf0"
          borderBottom="2px solid"
          borderColor="#00acf0"
          width="40%"
          p="2"
          rounded="sm"
          _hover={{ bg: "black", color: "white", borderBottom: "none" }}
        >
          Stats
        </Link>
      </ScrollLink>
      <ScrollLink to="contact" smooth={true} duration={700} offset={-100}>
        <Link
          mr="3"
          color="#00acf0"
          borderBottom="2px solid"
          borderColor="#00acf0"
          width="40%"
          p="2"
          rounded="sm"
          _hover={{ bg: "black", color: "white", borderBottom: "none" }}
        >
          Contact Us
        </Link>
      </ScrollLink>
    </Flex>
  );
}

export default Header;
