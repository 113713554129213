import {
  Box,
  Container,
  Flex,
  Text,
  Center,
  Icon,
  Image,
} from "@chakra-ui/react";
import catalyst from "../media/catalyst.jpg";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Services() {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const [ref2, inView2] = useInView({
    triggerOnce: true,
  });

  return (
    <Box py="10" id="services" mb={{ base: "15", md: "150" }}>
      <Container maxW={["100%", "80%", "768px", "1024px", "1280px"]} mx="auto">
        <Center fontSize="3xl" mb="20" mt={{ base: "0", md: "50" }}>
          <Text>
            Our Services
            <Box
              content=""
              display="block"
              clear="both"
              width="55%"
              borderBottom="4px solid #00acf0"
              mx="auto"
            />
          </Text>
        </Center>

        <Flex
          direction={{ base: "column", md: "row" }}
          alignItems={{ base: "center", md: "flex-start" }}
          justifyContent={{ base: "center", md: "space-between" }}
          flexWrap="wrap"
        >
          <Box order={{ md: 1 }} w={{ base: "100%", md: "50%" }}>
            <motion.div
              ref={ref}
              initial={{ y: "+100px", opacity: 0 }}
              animate={{ y: inView ? 0 : "100%", opacity: inView ? 1 : 0 }}
              transition={{ duration: 0.75 }}
            >
              <Image
                width="100%"
                height={{ base: "200px", md: "430px" }}
                src={catalyst}
              />
            </motion.div>
          </Box>

          <Box
            order={{ md: 2 }}
            p={{ base: "5", md: "24" }}
            w={{ base: "100%", md: "50%" }}
            // mb={{ base: "12" }}
          >
            <motion.div
              ref={ref}
              initial={{ y: "+100px", opacity: 0 }}
              animate={{ y: inView ? 0 : "50%", opacity: inView ? 1 : 0 }}
              transition={{ duration: 1 }}
            >
              <Text fontSize="2xl" mb="2">
                Catalyst Regeneration
              </Text>
              <Box
                content=""
                display="block"
                clear="both"
                width="5em"
                borderBottom="4px solid #00acf0"
                float="left"
              />
              <Text mt={{ base: "8", md: "16" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                interdum convallis tempus. Suspendisse vehicula eros id massa
                pharetra, id ultricies risus dictum.
              </Text>
            </motion.div>
          </Box>

          <Box order={{ md: 4 }} w={{ base: "100%", md: "50%" }}>
            <motion.div
              ref={ref2}
              initial={{ y: "+100px", opacity: 0 }}
              animate={{ y: inView2 ? 0 : "100%", opacity: inView2 ? 1 : 0 }}
              transition={{ duration: 0.75 }}
            >
              <Image
                width="100%"
                height={{ base: "200px", md: "430px" }}
                src={catalyst}
              />
            </motion.div>
          </Box>

          <Box
            order={{ md: 3 }}
            p={{ base: "5", md: "24" }}
            w={{ base: "100%", md: "50%" }}
            // mb={{ base: "12" }}
          >
            <motion.div
              ref={ref2}
              initial={{ y: "+100px", opacity: 0 }}
              animate={{ y: inView ? 0 : "50%", opacity: inView ? 1 : 0 }}
              transition={{ duration: 1 }}
            >
              <Text fontSize="2xl" mb="2">
                Catalyst Regeneration
              </Text>
              <Box
                content=""
                display="block"
                clear="both"
                width="5em"
                borderBottom="4px solid #00acf0"
                float="left"
              />
              <Text mt={{ base: "8", md: "16" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                interdum convallis tempus. Suspendisse vehicula eros id massa
                pharetra, id ultricies risus dictum.
              </Text>
            </motion.div>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}
