import { Box, Center, Container, Flex, Icon, Text } from "@chakra-ui/react";
import {
  GlobeAsiaAustraliaIcon,
  TrophyIcon,
  AcademicCapIcon,
  CheckBadgeIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";
import CountUp from "react-countup";

import ScrollTrigger from "react-scroll-trigger";

function Stats() {
  const [counter, setcounter] = useState(false);
  const handleEnterViewport = () => {
    setcounter(true);
  };

  return (
    <Container
      id="stats"
      maxW={["100%", "100%", "768px", "1024px", "1280px"]}
      mx="auto"
      mb="40"
    >
      <Center
        fontSize={{ base: "xl", md: "3xl" }}
        mb="20"
        mt={{ base: "0", md: "50" }}
      >
        {/* <Text>
          Catalyst pioneers Since 1990
        </Text> */}
      </Center>
      <Flex
        mx="auto"
        alignItems="center"
        justifyContent="center"
        direction={{ base: "column", md: "row" }}
      >
        <Box flex="1" mb={["12", "0"]}>
          <Flex mx="auto" alignItems="center" justifyContent="center">
            <Box>
              <Icon
                as={GlobeAsiaAustraliaIcon}
                height="14"
                width="14"
                color="#00acf0"
              />
            </Box>
            <Box>
              <Center fontSize="xl">
                <ScrollTrigger onEnter={handleEnterViewport} once>
                  {counter && (
                    <CountUp start={0} end={144} duration={4} delay={0} />
                  )}
                  +
                </ScrollTrigger>
              </Center>
              <Text>Global Reach</Text>
            </Box>
          </Flex>
        </Box>

        <Box flex="1" mb={["12", "0"]}>
          <Flex mx="auto" alignItems="center" justifyContent="center">
            <Box>
              <Icon as={TrophyIcon} height="14" width="14" color="#00acf0" />
            </Box>
            <Box>
              <Center fontSize="xl">
                <ScrollTrigger onEnter={handleEnterViewport} once>
                  {counter && (
                    <CountUp start={0} end={24} duration={4} delay={0} />
                  )}
                  +
                </ScrollTrigger>
              </Center>
              <Text>Industry Award</Text>
            </Box>
          </Flex>
        </Box>

        <Box flex="1" mb={["12", "0"]}>
          <Flex mx="auto" alignItems="center" justifyContent="center">
            <Box>
              <Icon
                as={AcademicCapIcon}
                height="14"
                width="14"
                color="#00acf0"
              />
            </Box>
            <Box>
              <Center fontSize="xl">
                <ScrollTrigger onEnter={handleEnterViewport} once>
                  {counter && (
                    <CountUp start={0} end={89} duration={4} delay={0} />
                  )}
                  +
                </ScrollTrigger>
              </Center>
              <Text>Years Experience</Text>
            </Box>
          </Flex>
        </Box>

        <Box flex="1">
          <Flex mx="auto" alignItems="center" justifyContent="center">
            <Box>
              <Icon
                as={CheckBadgeIcon}
                height="14"
                width="14"
                color="#00acf0"
              />
            </Box>
            <Box>
              <Center fontSize="xl">
                <ScrollTrigger onEnter={handleEnterViewport} once>
                  {counter && (
                    <CountUp start={0} end={70} duration={4} delay={0} />
                  )}
                  +
                </ScrollTrigger>
              </Center>
              <Text>Satisfied Clients</Text>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Container>
  );
}

export default Stats;
