import { Box, Text, Image, Link } from "@chakra-ui/react";
import Typewriter from "typewriter-effect";
import lg from "../media/lg.jpg";
import logo from "../media/logoo.jpg";
import Mid from "./Mid";
import Services from "./services";

function Landing() {
  return (
    <>
      <Box position="relative" textAlign="center">
        <Image
          src={lg}
          alt="Landing Image"
          w="100%"
          h="60vh"
          objectFit="cover"
        />
        <Box
          position="absolute"
          top={0}
          left="50%"
          transform="translateX(-50%)"
          mt=""
          w="100%"
        >
          <Image
            src={logo}
            alt="Company Logo"
            w="20"
            h="20"
            rounded="17px"
            mx="auto"
            mt="24"
          />
          <Text color="white" fontSize="2xl" mt="5">
            Al-Bilad Catalyst Co. Ltd.
          </Text>
          <Text color="white" fontSize="lg" fontStyle="italic">
            (Part of the Eurecat Group)
          </Text>
          <Text
            color="white"
            fontSize={{ base: "xl", md: "3xl" }}
            mt={{ base: "20", md: "20" }}
            mb="24"
            w=""
          >
            <Typewriter
              options={{
                delay: 120,
              }}
              onInit={(typewriter) => {
                typewriter
                  .typeString(" Your partner for a complete ")
                  .typeString(
                    '<span  style="color: #00acf0;font-weight:500"  >Catalyst</span>'
                  )
                  .typeString(" Solution")
                  .pauseFor(1000)
                  .start();
              }}
            />
          </Text>
        </Box>
      </Box>
    </>
  );
}

export default Landing;
