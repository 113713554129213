import {
  Box,
  Container,
  Flex,
  Text,
  Icon,
  Image,
  Divider,
} from "@chakra-ui/react";
import img from "../media/com.jpg";
import {
  CpuChipIcon,
  ClockIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/24/solid";

export default function Mid() {
  return (
    <>
      <Container
        id="about"
        maxW={["100%", "100%", "768px", "1024px", "1280px"]}
        mx="auto"
        mt={{ base: "10" }}
      >
        <Flex direction={["column", "column", "row"]} gap="1">
          <Box textAlign="" p="4" borderRadius="md" flex="1">
            <Box mb="10">
              <Text fontSize="xl" fontWeight="bold" mb="2">
                About Us
              </Text>
              <Box
                content=""
                display="block"
                clear="both"
                width="5em"
                margin="0 0 1.4em"
                borderBottom="4px solid #00acf0"
                float="left"
              />
            </Box>
            <Text>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              interdum convallis tempus. Suspendisse vehicula eros id massa
              pharetra, id ultricies risus dictum. Curabitur posuere, lacus
              tempus finibus pulvinar, lectus purus euismod justo, et egestas
              sem metus quis sapien. Suspendisse rutrum aliquet mollis. Etiam a
              lorem et odio tempus feugiat ut ac tellus.
            </Text>
          </Box>
          <Box textAlign="" p="4" borderRadius="md" flex="1">
            <Image src={img} alt="Company" mx="auto" mb="4" />
          </Box>
        </Flex>
      </Container>
    </>
  );
}

// <Flex direction={["column", "column", "row"]} gap="2">
//             <Box textAlign="" p="4" borderRadius="md" flex="1">
//               <Text fontSize="xl" fontWeight="bold" mb="10">
//                 Get to know us
//               </Text>
//               <Image src={img} alt="Company" mx="auto" mb="4" />
//               <Text>
//                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
//                 interdum convallis tempus. Suspendisse vehicula eros id massa
//                 pharetra, id ultricies risus dictum. Curabitur posuere, lacus
//                 tempus finibus pulvinar, lectus purus euismod justo, et egestas
//                 sem metus quis sapien. Suspendisse rutrum aliquet mollis. Etiam
//                 a lorem et odio tempus feugiat ut ac tellus.
//               </Text>
//             </Box>

//             <Box flex="1" p="4">
//               <Text fontSize="xl" fontWeight="bold" mb="10">
//                 Why choose AlBilad?
//               </Text>
//               {/* ///////1////// */}
//               <Flex direction="column" gap="1" mb="10">
//                 <Flex direction="row">
//                   <Box>
//                     <Icon as={ClockIcon} h={12} w={12} color="#2d2d9e" mr="3" />
//                   </Box>
//                   <Box>
//                     <Text fontSize="lg" mb="5">
//                       Punctual Delivery Time
//                     </Text>
//                     <Text mt="4">
//                       Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//                       Sed interdum convallis tempus. Suspendisse vehicula eros
//                       id massa pharetra, id ultricies risus dictum.
//                     </Text>
//                   </Box>
//                 </Flex>
//               </Flex>
//               {/* ////////2//////// */}
//               <Flex direction="column" gap="1" mb="10">
//                 <Flex direction="row">
//                   <Box>
//                     <Icon
//                       as={ClipboardDocumentCheckIcon}
//                       h={12}
//                       w={12}
//                       color="#2d2d9e"
//                       mr="3"
//                     />
//                   </Box>
//                   <Box>
//                     <Text fontSize="lg" mb="5">
//                       Punctual Delivery Time
//                     </Text>
//                     <Text mt="4">
//                       Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//                       Sed interdum convallis tempus. Suspendisse vehicula eros
//                       id massa pharetra, id ultricies risus dictum.
//                     </Text>
//                   </Box>
//                 </Flex>
//               </Flex>
//               {/* /////////3/////// */}
//               <Flex direction="column" gap="1">
//                 <Flex direction="row">
//                   <Box>
//                     <Icon
//                       as={CpuChipIcon}
//                       h={12}
//                       w={12}
//                       color="#2d2d9e"
//                       mr="3"
//                     />
//                   </Box>
//                   <Box>
//                     <Text fontSize="lg" mb="5">
//                       Smart Technology
//                     </Text>
//                     <Text mt="4">
//                       Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//                       Sed interdum convallis tempus. Suspendisse vehicula eros
//                       id massa pharetra, id ultricies risus dictum.
//                     </Text>
//                   </Box>
//                 </Flex>
//               </Flex>
//               {/* /////////// */}
//             </Box>
//           </Flex>
