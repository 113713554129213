import {
  FormControl,
  FormLabel,
  Input,
  Button,
  Box,
  Heading,
  Text,
  Center,
  Textarea,
  Flex,
  Icon,
  Divider,
  Container,
  Spinner,
} from "@chakra-ui/react";
import { PhoneIcon, EnvelopeIcon, MapPinIcon } from "@heroicons/react/24/solid";
import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";

export default function SendMail() {
  const form = useRef();
  const emailRef = useRef();
  const nameRef = useRef();
  const messageRef = useRef();
  const [message, setmessage] = useState("");
  const [loading, setLoading] = useState(false);

  const sendEmail = (e) => {
    console.log(loading);
    e.preventDefault();
    setLoading(true);
    setmessage(" ");
    if (
      !emailRef.current.value ||
      !nameRef.current.value ||
      !emailRef.current.value
    ) {
      setmessage("please fill all feilds!");
      setLoading(false);
      return;
    }
    console.log(loading);
    emailjs
      .sendForm(
        "service_e6qycco",
        "template_3woojvs",
        form.current,
        "xDHg89uDATfrQH3-M"
      )
      .then(
        (result) => {
          nameRef.current.value = " ";
          emailRef.current.value = " ";
          messageRef.current.value = " ";
          setLoading(false);
          setmessage("sent!");
        },
        (error) => {
          setmessage(error.text);
        }
      );
  };

  return (
    <Container
      id="contact"
      maxW={["100%", "100%", "768px", "1024px", "1280px"]}
      mx="auto"
    >
      <Flex
        mx="auto"
        alignItems="center"
        justifyContent="center"
        direction={{ base: "column", md: "row" }}
      >
        <Box flex="1" mb={["12", "0"]}>
          <Box mb="14">
            <Text fontSize="3xl" mb="2">
              Contact Us
            </Text>
            <Box
              float="left"
              mx="auto"
              width="6em"
              borderBottom="4px solid #00acf0"
            />
          </Box>
          <Box flex="1" display="flex">
            <Icon as={PhoneIcon} h={7} w={7} color="#2d2d9e" />
            <Text ml="1" mt="1">
              +966 133589080
            </Text>
          </Box>
          <Box flex="1" display="flex" mt="5">
            <Icon as={EnvelopeIcon} h={7} w={7} color="#2d2d9e" />
            <Text ml="1">General@bilad-catalyst.com</Text>
          </Box>
          <Box flex="1" display="flex" mt="5">
            <Icon as={MapPinIcon} h={7} w={7} color="#2d2d9e" />
            <Text ml="1">
              Street 198, Jubail Industrial City, 31961, Saudi Arabia
            </Text>
          </Box>
        </Box>

        <Box flex="1" width={{ base: "100%" }}>
          <form onSubmit={sendEmail} ref={form}>
            <FormControl mb={4}>
              <FormLabel>Email </FormLabel>
              <Input
                type="email"
                placeholder="your email address"
                name="user_email"
                ref={emailRef}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                placeholder="your full name"
                name="user_name"
                ref={nameRef}
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Message</FormLabel>
              <Textarea
                type="text"
                placeholder="your Message"
                name="message"
                ref={messageRef}
              />
            </FormControl>

            <Button
              isDisabled={loading}
              type="submit"
              colorScheme="blue"
              width="full"
              backgroundColor="#00acf0"
            >
              Send
              {loading && <Spinner ml="2" />}
            </Button>
            <Center color="red.300">{message}</Center>
          </form>
        </Box>
      </Flex>
    </Container>
  );
}
