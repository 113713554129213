import "./App.css";

import Landing from "./components/land";
import Mid from "./components/Mid";
import Services from "./components/services";
import Footer from "./components/footer";
import SendMail from "./components/sendmail";
import Stats from "./components/stats";
import Header from "./components/header";

function App() {
  return (
    <>
      <Header />
      <Landing />
      <Mid />
      <Services />
      <Stats />
      <SendMail />
      <Footer />
    </>
  );
}

export default App;
